import React from "react";
import Helmet from "react-helmet";
import Script from "components/script";
import YouTube from "react-youtube";

const NotFoundPage = () => {
  const [videoVisible, setVideoVisible] = React.useState(false);

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"PITC 2023 Loading…"}
        meta={[
          {
            name: `description`,
            content: "2023 @ Exeter Castle",
          },
          {
            property: `og:url`,
            content: `https://partyinthecastle.co.uk`,
          },
          {
            property: `og:title`,
            content: "Party in the Castle",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: `/flyer.jpg`,
          },
        ]}
      ></Helmet>
      <div className="header">
        <div className="loader"></div>
        <div className="video" style={{ opacity: videoVisible ? 1 : 0 }}>
          <YouTube
            containerClassName={"video-wrapper"}
            videoId="st-xjNzhIEU"
            onPlay={() => {
              setVideoVisible(true);
            }}
            onReady={(e) => {
              e.target.playVideo();
            }}
            opts={{
              height: "800",
              width: "600",
              // defaults -> noop

              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                playlist: "st-xjNzhIEU",
                autoplay: 1,
                loop: 1,
                mute: 1,
                showinfo: 0,
                modestbranding: 1,
                playsinline: 1,
                rel: 0,
                fs: 0,
                controls: 0,
              },
            }}
          />
        </div>
      </div>
      <div className="wrapper">
        <img className="logo" src="/pitc23.png" alt="" />
        <main>
          <div>
            <h2>PITC 2023 Loading…</h2>
            {/* <p
              style={{
                textAlign: "center",
                color: "#7f7f7f",
                fontWeight: "bold",
                margin: "0 0 32px 0",
              }}
            >
              2023 ⏰
            </p> */}
          </div>

          <div className="ticket-buttons">
            <a
              className="button"
              href="https://fixr.co/event/party-in-the-castle-2023-tickets-697560921"
            >
              BUY TICKETS
            </a>
          </div>
          <div>
            <img className="flyer" src="/flyer.jpg" alt="" />
          </div>
          <div className="info">
            <div>
              <p>
                <span style={{ fontSize: "24px" }}>
                  🏰 PARTY IN THE CASTLE // EXETER 🏰
                </span>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>
                  Friday 22nd September 2023
                </span>
              </p>

              <p>
                <span style={{ fontSize: "20px" }}>
                  🎟 Limited TICKETS remain!
                </span>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ Line Up ☆</span>
              </p>

              <p>
                BIG NARSTIE (Live)
                <br />
                WES NELSON (Live Pa)
                <br />
                ISSEY CROSS (Live PA)
                <br />
                AYO BEATZ
                <br />
                IRONIK
                <br />
                AKUKO
                <br />
                MC IBZ
                <br />
                JAMIEG
                <br />
                FREEJAK
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>
                  + REDBULL DJ TRUCK x SSX
                </span>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>
                  SNOWSPORTS DJ SHOWCASE
                </span>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>+ RESIDENTS</span>
              </p>

              <p>
                Back in the oldest castle in England for its 5th edition, PITC
                promises to be the biggest Exeter city centre festival of summer
                2023! Party within the castle walls in the most exclusive
                setting with some of the biggest names in the industry.
              </p>

              <p>
                PITC is one of the most anticipated events in the social
                calendar. In a short space of time, this young event established
                itself as THE go-to event of the summer .
              </p>

              <p>
                The PITC stage consistently plays host to world-class acts and
                DJs. It has been graced by Rudimental, Nathan Dawe, Shy FX,
                Example, High Contrast, Jaguar Skills, to name a few…
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ AFTER PARTY ☆</span>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>TIMEPIECE</span>
              </p>

              <p>
                This event is <span style={{ fontWeight: "bold" }}>18+</span> |
                ID will be required
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ FOOD</span>
              </p>

              <p>
                Serving delicious HOT food throughout the day. Menu can be found
                on our social pages.
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ DRINK</span>
              </p>

              <p>
                Fully licensed bars open all day. Own drink is not permitted.
                <br />
                3x for £10 on cans, VK & Venom Bar + much more.
              </p>

              <p>Proudly Sponsored by: SANFORD ORCHARDS</p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ PAYMENTS</span>
              </p>

              <p>
                There is very limited ATM access. Therefore, cash is strongly
                advised. We do, however, have facilities to take card payments
                at the bars.
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ REFUNDS</span>
              </p>

              <p>
                Tickets for PITC are{" "}
                <span style={{ fontWeight: "bold" }}>non-refundable</span>.
              </p>

              <p>
                *Set times, line up, and durations of acts are approximate and
                occasionally have to be altered. Refunds will not be issued for
                this. We endeavor to make sure acts adhere to the agreed
                schedule, but events beyond our control can occur.
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ CONTACT</span>
              </p>

              <p>
                Email:{" "}
                <a href="mailto:info@partyinthecastle.co.uk">
                  info@partyinthecastle.co.uk
                </a>
                <br />
                Phone: <a href="tel:01392490948">01392 490948</a>
                <br />
                Web:{" "}
                <a href="http://www.partyinthecastle.co.uk">
                  www.partyinthecastle.co.uk
                </a>
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>☆ WORK WITH US</span>
              </p>

              <p>
                Get in touch above, we are always looking for REPS, BAR STAFF,
                and General event staff.
              </p>

              <p>
                <span style={{ fontWeight: "bold" }}>
                  IMPORTANT INFORMATION.
                </span>
              </p>

              <p>
                - No tickets will be available on the gate
                <br />
                - There is a zero-tolerance drug policy
                <br />
                - Be aware: strobe lighting & other special effects in operation
                as well as our film crew.
                <br />
                - Last entry 6pm
                <br />- No Re-entry
              </p>

              <p>*Subject to license</p>
            </div>
          </div>
        </main>
      </div>
      <footer>
        <div className="wrapper">
          <a href="https://www.facebook.com/partyinthecastleofficial">
            Follow us on <img src="/facebook.svg" />
          </a>
          <a href="https://fixr.co" className="fixr-link">
            site by <img src="/fixr.svg" />
          </a>
        </div>
      </footer>
      <Script
        id="checkout"
        src="https://web-cdn.fixr.co/scripts/fixr-checkout-widget.v1.min.js?salesChannel=whitelabel-website"
      ></Script>
    </div>
  );
};

export default NotFoundPage;
