import React, { useEffect, useRef, useState } from "react";

const Script = ({ src, id }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (src) {
      try {
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        containerRef.current.appendChild(script);
      } catch (e) {
        setError(true);
      }
    }
  }, [src, id]);

  return <div ref={containerRef}></div>;
};

export default Script;
